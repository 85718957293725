import { useEffect, useState } from "react";
import TaskItem from "../tasks/TaskItem";
import { Task, TaskExample } from "../interfaces/Task";
import { useNavigate } from "react-router-dom";
import TaskItemExample from "../tasks/TaskItemExample";
import { Tab, Tabs } from "react-bootstrap";
import TaskItemShared from "../tasks/TaskItemShared";

export default function MyRecentTasks() {
    const navigate = useNavigate();
    const [tabKey, setTabKey] = useState('my-tasks');

    const userId = localStorage.getItem('userId');

    const [tasks, setTasks] = useState<Task[]>([]);
    const [taskId, setTaskId] = useState('');
    const [showExamples, setShowExamples] = useState(false);
    const [examples, setExamples] = useState<TaskExample[]>([]);
    const [shared, setShared] = useState<any[]>([]);

    const handleNewTask = () => {
        // Create a new task
        const requestOptionsPost = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                name: 'New task',
                pretasks: [],
                tasks: [],
                postasks: [],
                updated: new Date(),
                userId: userId,
                active: false
            })
        };
        fetch('https://srv.taskgen.eu/v1/task', requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                const taskId =  data._id;
                setTaskId(taskId);
                
                // Decide if user has to set level or not
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                };
                fetch('https://srv.taskgen.eu/v1/user/' + userId, requestOptions)
                    .then(response => {
                        if (response.status !== 200) {
                            navigate('/');
                        }
                        return response.json();
                    })
                    .then(data => {
                        navigate('/edit-task/' + taskId);
                    });
            });
    }

    const handleDeleteTask = (id: string) => {
        const newTasks = tasks.filter((t: Task) => t._id != id);
        setTasks(newTasks);
    }

    const handleDuplicateTask = (task: Task) => {
        const newTask = Object.assign({}, task);
        newTask.name = task.name;
        const newTasks = [newTask, ...tasks];
        setTasks(newTasks);
    }

    const handleDuplicateTaskExample = (task: Task) => {
        const newTask = Object.assign({}, task);
        const newTasks = [newTask, ...tasks];
        setTasks(newTasks);
        setShowExamples(false);
        alert('The task has been cloned. You will find the cloned task in "My Task designs" in the dashboard')
    }

    const handleDuplicateTaskShared = (task: Task) => {
        const newTask = Object.assign({}, task);
        const newTasks = [newTask, ...tasks];
        setTasks(newTasks);
        setShowExamples(false);
        alert('The task has been cloned. You will find the cloned task in "My Task designs" in the dashboard')
    }

    const handleShowExamples = () => {
        setTabKey('example-tasks');
    }

    const handleBackToTasks = () => {
        setShowExamples(false);
    }

    useEffect(() => {
        const requestOptionsGet = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/tasks?userId=' + userId, requestOptionsGet)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setTasks(data);
            });

        // Load example tasks
        const requestOptionsPost = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/examples/', requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setExamples(data);
            });   
        
        // Load shared tasks
        fetch('https://srv.taskgen.eu/v1/user/shares/' + userId, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                const newShared: any[] = [];
                data.sort((a, b) => a.updated > b.updated ? 1 : -1).map((d: any) => {
                    if (d.task != null) {
                        if (newShared.findIndex((i: any) => i.id == d.task._id) === -1) {
                            newShared.push({
                                _id: d.task._id,
                                name: d.task.name,
                                updated: d.task.updated,
                                userId: d.task.userId
                            });
                        }
                    }
                });
                setShared(newShared);
            });   
    }, []);

    const handleSortUpTask = (position: number) => {
        if (position > 0) {
            const newTasks: Task[] = tasks.map((pt: Task, index) => {
                let t: Task = Object.assign({}, pt); 
                t.weight = index;
                if (index == position - 1) {
                    t.weight = position;
                    
                }
                if (index == position) {
                    t.weight = position - 1;
                }
                return (t);
            });
            newTasks.sort((a: Task, b: Task) => a.weight - b.weight);
            setTasks(newTasks);
            
            for (let i = 0; i < newTasks.length; i++) {
                const requestOptions = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                    body: JSON.stringify(newTasks[i])
                };
                fetch('https://srv.taskgen.eu/v1/task/' + newTasks[i]._id, requestOptions)
                    .then(response => {
                    if (response.status !== 200) {
                        navigate('/');
                    }
                    return response.json();
                }).then(data => {});
            }
        }
    }

    const handleSortDownTask = (position: number) => {
        if (position < tasks.length - 1) {
            const newTasks: Task[] = tasks.map((pt: Task, index) => {
                let t: Task = Object.assign({}, pt); 
                t.weight = index;
                if (index == position + 1) {
                    t.weight = position;
                }
                if (index == position) {
                    t.weight = position + 1;
                }
                return (t);
            });
            newTasks.sort((a: Task, b: Task) => a.weight - b.weight);
            setTasks(newTasks);

            for (let i = 0; i < newTasks.length; i++) {
                const requestOptions = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                    body: JSON.stringify(newTasks[i])
                };
                fetch('https://srv.taskgen.eu/v1/task/' + newTasks[i]._id, requestOptions)
                    .then(response => {
                    if (response.status !== 200) {
                        navigate('/');
                    }
                    return response.json();
                }).then(data => {});
            }
        }
    }

    return(        
        <div className="my-recent-tasks box">
            <Tabs id="task-tabs" defaultActiveKey={"my-tasks"} activeKey={ tabKey } onSelect={(k) => setTabKey(k ?? 'my-tasks')}>
                <Tab eventKey="my-tasks" title={ 'My task designs' }>
                    { tasks.length == 0 && !showExamples &&
                        <div className="no-tasks">
                            <div className="margin-bottom-small">
                                <button className="btn btn-primary btn-lg" onClick={ handleNewTask }>
                                    { 'Wish to design a task?' }
                                </button>
                            </div>
                            <div className="margin-bottom-small">
                                { 'or get some inspiration' }
                            </div>
                            <button className="btn btn-primary" onClick={ handleShowExamples }>
                                { 'See some examples' }
                            </button>
                        </div>
                    }
                    { tasks.length > 0 && !showExamples &&
                        <div className="has-tasks">
                            <div className="actions">
                                <button className="btn btn-primary create" title={ 'Create a new task' }
                                    onClick={ handleNewTask }>
                                    { 'Create a new task' }
                                </button>
                            </div>
                            <div className="tasks-list">
                                { tasks.map((t: Task, index) => {
                                        return(
                                            <TaskItem position={ index }
                                                deleteCallback={ handleDeleteTask } 
                                                duplicateCallback={ handleDuplicateTask }
                                                sortUpCallback={ handleSortUpTask }
                                                sortDownCallback={ handleSortDownTask }
                                                task={ t } key={ 'task-' + t._id } />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }
                </Tab>
                <Tab eventKey="shared-tasks" title={ 'Shared with me' }>
                    <div className="shared-container">
                        {
                            shared.map((e: Task) => {
                                return <TaskItemShared task={ e } duplicateCallback={ handleDuplicateTaskExample }
                                    key={ 'task-shared-' + e._id } />;
                            })
                        }
                    </div>
                </Tab>
                <Tab eventKey="example-tasks" title={ 'Task examples' }>
                    <div className="examples-container">
                        {
                            examples.map((e: TaskExample) => {
                                return <TaskItemExample task={ e } duplicateCallback={ handleDuplicateTaskShared }
                                    key={ 'task-example-' + e._id } />;
                            })
                        }
                    </div>
                </Tab>
            </Tabs>
        </div>);
}
